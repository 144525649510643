import './App.css';
import {useEntries} from './hooks/useEntries';

function App() {
  const repeatCount = 10;
  const results = useEntries('meekb-modnar', [
    'monument_output',
    'item_output',
    'npc_output',
    'location_output',
    'encounter_output',
    'quest_output',
    'trap_output',
  ], repeatCount);

  const makeOrderedList = (entries, listIndex) => {
    const listMarkup = entries.map(({list, value}, index) => (
      <li key={`li-${list}`}>{value}</li>
    ));
    return (<ol>{listMarkup}</ol>);
  };

  const makeDefinitionList = (listData) => {
    const listMarkup = listData.map(({list, values}, index) => (
      <>
        <dt key={`dt-${index}`}>{list}</dt>
        <dd key={`dd-${index}`}>{values.length === 1 ? values[0].value : makeOrderedList(values, index)}</dd>
      </>
    ));
    return (<dl>{listMarkup}</dl>);
  };

  return (
    <div class="container">
      <div class="box">
        <h1 class="header">
          <span class="logo" />
          <span class="text">MODNAR</span>
        </h1>
        {makeDefinitionList(results)}
      </div>
    </div>
  );
}

export default App;
